:root {
	--bg: rgb(0, 10, 20);
	--bg: #021222;
	--bg2: #071c31;
	--bg2: hsl(210deg 50% 15%);
	--bg2: hsl(210 100% 12% / 1);
	--scroll: 0;
	--offset-direction: 1;
}

body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
	background-color: var(--bg);
	/*--color: hsl(210 100% 97% / 1);
	--color: hsl(45 100% 90% / 1);
	--color: wheat;*/
	--color: white;
	color: white;
	position: relative;
	line-height: 1.4;
}

a {
	text-decoration: none;
	color: inherit;
	font-weight: 600;
}

hr {
	margin: 32px 0;
	border: none;
	border-bottom: solid 1px rgba(255, 255, 255, .15);
}

p {
	margin: 24px 0;
}

textarea {
	padding: 8px;
	box-sizing: border-box;

	font-family: inherit;
	color: rgba(255, 255, 255, .75);
	background-color: rgba(255, 255, 255, .25);
	border: solid 1px rgba(0, 0, 0, .25);
	border: none;
	border-radius: 8px;
	outline: none;
}

button {
	padding: 8px 16px;
	font-family: inherit;
	color: white;
	background-color: #17497a;
	border-radius: 4px;
	border: none;
	outline: none;
	cursor: pointer;
}

button:disabled {
	color: rgba(255, 255, 255, .35);
	background-color: rgba(255, 255, 255, .15);
	cursor: default;
}
